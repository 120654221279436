import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { usePagination, useWindowSize } from '@hooks'
import { useQuery } from '@apollo/client'
import { CacheUser, TableColumnType, ResponseType, Order } from '@types'
import { Pagination, StatusIcon, Input, MobileGolfClubCard, EmptyTable } from '@elements'
import { Table, Select } from '@components'
import { AccountLayout } from '@layouts'
import { user as graphqlUser, order as graphqlOrder } from '@graphql'
import { zeroPad, getDateISO } from '@utils'
import PaginationArrow from '@images/collapse-arrow.svg'
import SearchImage from '@images/search.svg'
import './my-sales-history.scss'

interface AccountMySalesHistoryProps {}

const STATUSES = [
  { label: 'All statuses', value: 'all' },
  { label: 'In progress', value: 'created' },
  { label: 'Complete', value: 'paid' },
  { label: 'Canceled', value: 'canceled' }
]

type OrdersFilterType = {
  status: string
  search: string
}

const renderStatusIcon = (status: string) => {
  switch (status) {
    case 'PAID':
      return <StatusIcon className={'account-my-sales-history-card-status-icon'} status={'Complete'} />
    case 'CREATED':
      return <StatusIcon className={'account-my-sales-history-card-status-icon'} status={'In Progress'} />
    case 'CANCELED':
      return <StatusIcon className={'account-my-sales-history-card-status-icon'} status={'Canceled'} />
    default:
      return <StatusIcon className={'account-my-sales-history-card-status-icon'} status={'In Progress'} />
  }
}

const AccountMySalesHistory: React.FC<AccountMySalesHistoryProps> = () => {
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const [filter, setFilter] = useState<OrdersFilterType>({
    status: 'all',
    search: ''
  })
  const { data } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })
  const currentUser = data && data.res

  const { content = [], pagination, fetchMore, refetch, loading } = usePagination<Order>(
    graphqlOrder.FetchPaginateOrders,
    {},
    { fetchPolicy: 'no-cache' },
    1,
    6
  )
  const handleMyOfferBookPagination = (page: number) => fetchMore({}, page)

  if (!loading && !currentUser) navigate('/home/')
  if (!currentUser) return null

  const handleOrderHistorySearch = async (search: string) => {
    setFilter({ ...filter, search })
    await refetch({ viewAsSeller: true, term: search, status: filter.status === 'all' ? undefined : filter.status })
  }
  const handleOrderHistoryStatusSearch = (status: string | null) => {
    if (status) {
      setFilter({ ...filter, status: status })
      refetch({ viewAsSeller: true, status: status === 'all' ? undefined : status.toUpperCase(), term: filter.search })
    }
  }

  const userOrderHistory = content
  const paginationTotalPages = pagination?.totalPages

  const columns: TableColumnType[] = [
    {
      title: 'Item',
      key: 'item',
      render(_, row) {
        const title = row.golfClub?.golfClubType?.golfClubModel?.title || row.golfEquipment?.golfEquipmentModel?.title
        return <>{title || '-'}</>
      }
    },
    {
      title: 'Brand',
      key: 'brand',
      render(_, row) {
        return <>{row?.golfClub?.golfClubType?.golfClubModel?.brand?.title || row.golfEquipment?.brand?.title || '-'}</>
      }
    },
    {
      title: 'Date',
      key: 'insertedAt',
      render(insertedAt) {
        if (!insertedAt) return ''
        return getDateISO(new Date(insertedAt))
      }
    },
    {
      title: 'Order number',
      key: 'number',
      render(number: number) {
        return number && zeroPad(number, 8)
      }
    },
    {
      title: 'Total',
      key: 'buyerTotalPrice',
      render(buyerTotalPrice: number) {
        return <>{buyerTotalPrice ? `$${buyerTotalPrice}` : '-'}</>
      }
    },
    {
      title: 'Order Status',
      key: 'status',
      render(_, row) {
        const status = row?.status
        return renderStatusIcon(status)
      }
    }
  ]

  return (
    <AccountLayout pageClassName={'account-my-sales-history'} hideLayout={isMobileScreen}>
      <h6 className={'account-layout-page-title'}>MY SALES HISTORY</h6>
      <div className={'account-my-sales-history-header'}>
        <div>
          <Input
            name={'golfClubsSearch'}
            prefix={<SearchImage />}
            value={filter.search}
            onChange={handleOrderHistorySearch}
            placeholder={'Search sales history'}
          />
        </div>
        <div>
          <Select
            name="statuses"
            options={STATUSES}
            value={STATUSES[0].value}
            clearable={false}
            onChange={handleOrderHistoryStatusSearch}
          />
        </div>
      </div>
      <div className={'account-my-sales-history-table'}>
        {isMobileScreen &&
          (!content.length ? (
            <EmptyTable title={loading ? 'Loading Your Sales History . . .' : 'Your Sales History is Empty'} />
          ) : (
            content.map(order => {
              const avatar = order?.golfClub?.golfClubType?.golfClubModel?.avatar || ''
              const title = order?.golfClub?.golfClubType?.golfClubModel?.title || '-'
              const subtitle = renderStatusIcon(order?.status)
              const data = [
                {
                  label: 'Brand',
                  value: order?.golfClub?.golfClubType?.golfClubModel?.brand?.title || '-'
                },
                {
                  label: 'Date & Time',
                  value: (order?.insertedAt || '-').toString()
                },
                {
                  label: 'Order number',
                  value: zeroPad(order?.number, 8).toString()
                },
                {
                  label: 'Total',
                  value: order?.buyerTotalPrice ? '$' + order.buyerTotalPrice : '-' // was order.price
                }
              ]
              return (
                <MobileGolfClubCard
                  key={order.id}
                  avatar={avatar}
                  title={title}
                  subtitle={subtitle}
                  data={data}
                  footer={
                    <div
                      className={'account-my-golf-club-inventory-table-card-footer'}
                      onClick={() => {
                        if (order.id) {
                          navigate(`/account/store/my-sales-history/${order.id}/details`)
                        }
                      }}
                    >
                      More
                      <PaginationArrow />
                    </div>
                  }
                />
              )
            })
          ))}
        {!isMobileScreen &&
          (!content.length ? (
            <EmptyTable title={loading ? 'Loading Your Sales History . . .' : 'Your Sales History is Empty'} />
          ) : (
            <Table
              columns={columns}
              rows={userOrderHistory}
              handleRowClick={row => navigate(`/account/store/my-sales-history/${row.id}/details`)}
            />
          ))}
        {(paginationTotalPages || 0) < 2 ? null : <Pagination {...pagination} onClick={handleMyOfferBookPagination} />}
      </div>
    </AccountLayout>
  )
}

export default AccountMySalesHistory
